import React, { useState } from 'react';
import { TheFirm } from './TheFirm';
import ItemListContainer from './ItemList/ItemListContainer';
import { bemify } from '@ja/helpers/bemClassHelper';
import './HeroSection.css';

const heroSectionClasses = bemify('hero-section');

const HeroSection = () => {
  const [jewelryListActive, setJewelryListActive] = useState(true);
  const [lastJewelryItem, setLastJewelryItem] = useState(0);
  const [lastPurposeItem, setLastPurposeItem] = useState(0);

  const toggleList = () => setJewelryListActive((prevState) => !prevState);

  const getLastHighlightedJewelryItem = (updatedLastJewelryItem = 0) =>
    setLastJewelryItem(updatedLastJewelryItem);
  const getLastHighlightedPurposeItem = (updatedLastPurposeItem = 0) =>
    setLastPurposeItem(updatedLastPurposeItem);

  return (
    <div className={heroSectionClasses()}>
      <TheFirm />
      {jewelryListActive && (
        <ItemListContainer
          list="jewelry"
          jewelryListActive={jewelryListActive}
          onTabClick={toggleList}
          getLastItem={getLastHighlightedJewelryItem}
          lastItem={lastJewelryItem}
        />
      )}
      {!jewelryListActive && (
        <ItemListContainer
          list="purpose"
          jewelryListActive={jewelryListActive}
          onTabClick={toggleList}
          getLastItem={getLastHighlightedPurposeItem}
          lastItem={lastPurposeItem}
        />
      )}
    </div>
  );
};

export default HeroSection;
