import React from 'react';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import { bemify } from '@ja/helpers/bemClassHelper';

import './UnderstandJewelry.css';

const bemClasses = bemify('understand-jewelry');

export const UnderstandJewelry = () => {
  return (
    <div className={bemClasses()}>
      <div className={bemClasses('descriptor')}>
        <small className={bemClasses('small-title')}>we keep it simple</small>
        <h3 className={bemClasses('title')}>Understand what you have</h3>
        <p className={bemClasses('subtitle')}>
          Whatever kind of jewelry piece you have, we want you to know what it
          really is.
        </p>
        <Media query={{ minWidth: 751 }}>
          <Link
            className={bemClasses('learn-more')}
            to="/appraisal-services/the-jewelry/"
            data-page>
            &gt; Discover what you have
          </Link>
        </Media>
        <Media query={{ maxWidth: 750 }}>
          <Link
            className={bemClasses('learn-more')}
            to="/appraisal-services/the-jewelry/"
            data-page>
            Discover what you have
          </Link>
        </Media>
      </div>
      <div className={bemClasses('imagery-wrapper')}>
        <div className={bemClasses('imagery')}>
          <div />
        </div>
      </div>
    </div>
  );
};
