import React from 'react';
import Media from 'react-media';
import PropTypes from 'prop-types';
import { bemify } from '@ja/helpers/bemClassHelper';

import './ListTabs.css';

const listTabsClasses = bemify('list-tabs');

const handleOnClick = (disabled, onClick) =>
  disabled
    ? (e) => e.preventDefault()
    : (e) => {
        e.preventDefault();
        onClick();
      };

const ListTabs = ({ onClick, disablePurposesTab, disableJewelryTab }) => (
  <span className={listTabsClasses('tab-container')}>
    <TabLabel />
    <RenderListTabs
      onClick={onClick}
      tabs={[
        {
          disabled: disableJewelryTab,
          text: 'Jewelry',
        },
        {
          disabled: disablePurposesTab,
          text: 'Purpose',
        },
      ]}
    />
  </span>
);

ListTabs.defaultProps = {
  disablePurposesTab: false,
  disableJewelryTab: true,
};

ListTabs.propTypes = {
  onClick: PropTypes.func.isRequired,
  disablePurposesTab: PropTypes.bool,
  disableJewelryTab: PropTypes.bool,
};

export default ListTabs;

function TabLabel() {
  return (
    <>
      <Media query={{ minWidth: 801 }}>
        <span className={listTabsClasses('tab-label')}>
          Filter services by:
        </span>
      </Media>
      <Media query={{ maxWidth: 800 }}>
        <span className={listTabsClasses('tab-label')} />
      </Media>
    </>
  );
}

function RenderListTabs({ tabs, onClick }) {
  return tabs.map(({ disabled, text }) => (
    <button
      key={text}
      className={listTabsClasses('tab', disabled ? 'active' : '')}
      onClick={handleOnClick(disabled, onClick)}>
      {text}
    </button>
  ));
}
