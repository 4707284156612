import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const PageHelmet = ({ canonicalPathname, description, title }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <link
      rel="canonical"
      href={`${process.env.REACT_APP_DOMAIN}${canonicalPathname}`}
    />
  </Helmet>
);

PageHelmet.propTypes = {
  canonicalPathname: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default PageHelmet;
