import './ItemList.css';

import React, { useState } from 'react';

import { bemify } from '@ja/helpers/bemClassHelper';
import ListTabs from './ListTabs';
import { useEffect } from 'react';

const theItemListClasses = bemify('the-items');

const ItemListComponent = ({
  lastItem,
  getLastItem,
  onItemClick,
  selectedList,
  onItemHover,
  onTabClick,
  jewelryListActive,
}) => {
  const [highlightItem, setHighlightItem] = useState(lastItem || 0);
  const [intervalState, setIntervalState] = useState(undefined);

  useEffect(() => {
    startRotateItems();

    return () => {
      getLastItem(highlightItem);
      stopRotateItems();
    };
  }, []);

  const startRotateItems = () => {
    const rotateItems = () => {
      setHighlightItem((prevState) =>
        prevState >= selectedList.length - 1 ? 0 : prevState + 1
      );
    };
    const updatedIntervalState = setInterval(rotateItems, 2200);
    setIntervalState(updatedIntervalState);
  };

  const stopRotateItems = (callback = () => {}) => {
    if (intervalState) {
      clearInterval(intervalState);
      setIntervalState(undefined);
      callback();
    }
  };

  const handleHoverOn = (e) => {
    onItemHover(e.target.getAttribute('data-item'));
    const updatedHighlightItem = Number(
      e.target.getAttribute('data-item-index')
    );
    stopRotateItems(() => setHighlightItem(updatedHighlightItem));
  };

  const handleOnClick = (e) => {
    onItemClick(selectedList[e.target.getAttribute('data-item-index')]);
    const updatedHighlightItem = Number(
      e.target.getAttribute('data-item-index')
    );
    stopRotateItems(() => setHighlightItem(updatedHighlightItem));
  };

  const handleHoverOff = () => {
    if (!intervalState) startRotateItems();
  };

  return (
    <div className={theItemListClasses()}>
      <ListTabs
        onClick={onTabClick}
        disablePurposesTab={!jewelryListActive}
        disableJewelryTab={jewelryListActive}
      />
      <ul className={theItemListClasses('list')}>
        {selectedList.map((item, idx) => {
          return (
            <li
              key={item.value}
              className={theItemListClasses('item', [
                highlightItem === idx ? 'highlight' : '',
              ])}
              data-item={item.value}
              data-item-index={idx}
              onMouseEnter={handleHoverOn}
              onMouseLeave={handleHoverOff}
              onClick={handleOnClick}>
              <span data-item-index={idx} data-item={item.value}>
                {item.value}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ItemListComponent;
