import React from 'react';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import { bemify } from '@ja/helpers/bemClassHelper';

import './UnderstandValue.css';

const bemClasses = bemify('understand-value');

export const UnderstandValue = () => {
  return (
    <div className={bemClasses()}>
      <div className={bemClasses('imagery-wrapper')}>
        <div className={bemClasses('imagery')}>
          <div />
        </div>
      </div>
      <div className={bemClasses('descriptor')}>
        <small className={bemClasses('small-title')}>we keep it real</small>
        <h3 className={bemClasses('title')}>Understand what it's worth</h3>
        <p className={bemClasses('subtitle')}>
          We want you to see every angle, then show you how that helps to assign
          a realistic value.
        </p>
        <Media query={{ minWidth: 751 }}>
          <Link
            className={bemClasses('learn-more')}
            to="/appraisal-services/the-purpose/"
            data-page>
            &gt; Discover the value
          </Link>
        </Media>
        <Media query={{ maxWidth: 750 }}>
          <Link
            className={bemClasses('learn-more')}
            to="/appraisal-services/the-purpose/"
            data-page>
            Discover the value
          </Link>
        </Media>
      </div>
    </div>
  );
};
