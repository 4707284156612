import React from 'react';
import PageHelmet from '@ja/components/common/PageHelmet';
import HeroSection from './HeroSection/HeroSection';
import ScrollToTopOnMount from '@ja/components/common/ScrollToTopOnMount';
import { UnderstandJewelry } from './UnderstandJewelry';
import { UnderstandValue } from './UnderstandValue';

import { bemify } from '@ja/helpers/bemClassHelper';

import './index.css';

const bemClasses = bemify('home');

const HomeComponent = () => (
  <>
    <PageHelmet
      description="Just Appraisers provides diamond, gemstone, (antique) jewelry, & watch appraisal services; located in NYC and Sedona."
      title="Just Appraisers | Diamond & Jewelry Appraisals"
      canonicalPathname="/"
    />
    <div className={bemClasses()}>
      <ScrollToTopOnMount />
      <HeroSection />
      <div className={bemClasses('value-props')}>
        <UnderstandJewelry />
        <UnderstandValue />
      </div>
    </div>
  </>
);

export default HomeComponent;
