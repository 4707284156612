import { connect } from 'react-redux';
import {
  getCurrentAppraisalItem,
  getCurrentAppraisalType,
} from '@ja/redux/actions/user';
import ItemListComponent from './ItemListComponent';
import {
  setSelectedJewelry,
  setSelectedPurpose,
} from '@ja/redux/actions/yourAppraisal';
import { openModal } from '@ja/redux/actions/modal';
import { APPRAISAL_CONSTANTS } from '@ja/pages/AppraisalServices/appraisalConstants';

const mapStateToProps = (_state, { list }) => {
  let selectedList = APPRAISAL_CONSTANTS.JEWELRY;

  if (list === 'purpose') {
    selectedList = APPRAISAL_CONSTANTS.PURPOSES;
  }
  return { selectedList };
};

const mapDispatchToProps = (dispatch, { list }) => {
  let onItemHover = () => {};
  let onItemClick = () => {};

  if (list === 'purpose') {
    onItemHover = (type) => dispatch(getCurrentAppraisalType({ type }));
    onItemClick = (item) => {
      dispatch(setSelectedPurpose(item));
      dispatch(
        openModal({
          modalType: 'PURPOSE_ITEM',
          modalContent: item,
        })
      );
    };
  } else {
    onItemHover = (item) => {
      dispatch(getCurrentAppraisalItem({ item }));
    };
    onItemClick = (item) => {
      dispatch(setSelectedJewelry(item));
      dispatch(
        openModal({
          modalType: 'JEWELRY_ITEM',
          modalContent: item,
        })
      );
    };
  }

  return {
    onItemHover,
    onItemClick,
  };
};

const ItemListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemListComponent);

export default ItemListContainer;
