import React from 'react';
import Media from 'react-media';
import { bemify } from '@ja/helpers/bemClassHelper';

import './TheFirm.css';

const theFirmClasses = bemify('the-firm');

export const TheFirm = (props) => {
  return (
    <>
      <div className={theFirmClasses()}>
        <h2 className={theFirmClasses('title', 'numb')}>hey there.</h2>
        <h2 className={theFirmClasses('title')}>
          we are <em>the</em>
          <br />
          independent <br />
          appraisal firm{' '}
          <Media query={{ minWidth: 851 }}>
            <br />
          </Media>
          for
        </h2>
      </div>
    </>
  );
};
